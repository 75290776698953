import './obligationSummary.scss';

import { IObligations, SavedObligationData, StatusChose, UserRole } from 'constants/commonExportedInterfaces';
import React, { useEffect, useState } from 'react';
import {
    addNotificationData,
    getSavedObligation,
    saveAnalystObligations,
    saveReviewerObligations,
    setPaginationExternally,
    storeEditSumText,
    updateEditSumText,
    updateStatusInObligationsToSave,
} from 'app/views/home/redux/homeActions';
import {
    getEditSumText,
    retrieveHashValue,
    selectNotificationsData,
    selectObligation,
} from '../../views/home/redux/homeSelectors';
import { headerAnalyst, headerReviewer } from './tableHeader';
import { limit, percentage } from 'constants/constants';
import { retrieveAllPagesFile, selectObligationsToSave } from 'app/views/home/redux/homeSelectors';

import ApproveIcon from '../reusable/approveIcon/ApproveIcon';
import ChipComponent from '../nds/chip/chip';
import ControlButtons from '../reusable/controlButtons/ControlButtons';
import Edit24px from '@nexus/core/dist/assets/icons/editor/ic_mode_edit_24px.svg';
import ModalComponent from '../nds/modal/modal';
import { NexusIcon } from '@nexus/react';
import NoRecordsTemplate from 'app/components/reusable/noRecords/NoRecordsTemplate';
import PaginationComponent from '../nds/pagination/pagination';
import RejectIcon from '../reusable/rejectIcon/RejectIcon';
import TextareaComponent from '../nds/textarea/textarea';
import TitleBar from '../reusable/titleBar/TitleBar';
import TooltipComponent from '../nds/tooltip/tooltip';
import { getUserRole } from 'app/views/home/redux/homeSelectors';
import { getValuePercentage } from 'services/getPercentagevalue';
import i18n from 'locales/i18n';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';

const ObligationSummary: React.FC = () => {
    const userRole = useSelector(getUserRole);
    const notificationsData = useSelector(selectNotificationsData);
    const obligationsToSave = useSelector(selectObligationsToSave);
    const hash = useSelector(retrieveHashValue);
    const editedSumText = useSelector(getEditSumText);
    const fullObligationsData = useSelector(selectObligation);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [sumText, setSumText] = useState<string>('');
    const [obgName, setObgName] = useState<string>('');
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<number>(1);
    const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(true);
    const [tooltipPosition, setTooltipPosition] = useState<string>('bottom');

    const allPagesFiles = useSelector(retrieveAllPagesFile);

    const modalBody = (
        <div className='nexus-col-md-8'>
            <TextareaComponent
                value={sumText}
                onInputHandler={(event: React.FormEvent<HTMLNexusTextareaElement>) => editSumText(event)}
            />
        </div>
    );

    const selectedPage = (data: number) => {
        setPage(data);

        dispatch(setPaginationExternally(false));
    };

    const pagesVisited = () => {
        return (page - 1) * limit;
    };

    const handleMouseMove = (e: MouseEvent): void => {
        const remainingSpace = window.innerHeight - e.clientY;
        if (remainingSpace > 200) {
            setTooltipPosition('bottom');
        } else {
            setTooltipPosition('top');
        }
    };
    useEffect(() => {
        setPage(1);

        if (userRole === UserRole.REVIEWER && allPagesFiles.home.length) {
            dispatch(getSavedObligation(hash));
        }
    }, [userRole]);

    useEffect(() => {
        setTooltipPosition('bottom');
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const validateTableRows = () => {
        const option = userRole === UserRole.REVIEWER ? 'status' : 'status_to_be_reviewed';
        const rowsStatuses = obligationsToSave.map((obligation: IObligations) => obligation[option]);
        // const valid = rowsStatuses.every((status: string) => status !== '');
        const valid = rowsStatuses.some((status: string) => status !== '');

        return !valid;
    };

    const handleSave = () => {
        const body: SavedObligationData = {
            Reviewer: 'string',
            Submit: 'string',
            User: 'string',
            User_ID: 'string',
            citation: fullObligationsData.citation,
            comments: 'string',
            draftobg: obligationsToSave,
            filesize: 0,
            regulation_id: fullObligationsData.regulation_id,
            regulation_name: fullObligationsData.regulation_name,
            regulation_type: 'string',
            run_id: hash,
            source_url: fullObligationsData.source_url,
            status: 'string',
        };

        if (userRole !== UserRole.ANALYST) {
            /**
             * @Backend not ready before DEMO 28th October 2022
             * todo when new JAVA Backend has endpoint create ACTION.saveReviewerObligations
             * */
            dispatch(saveReviewerObligations(body));
            return;
        }

        dispatch(saveAnalystObligations(body));
    };

    const handleSubmit = () => {
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 5000,
                    variant: 'success',
                },
            ]),
        );
    };

    const handleOptionsChange = (value: StatusChose, name: string) => {
        dispatch(updateStatusInObligationsToSave({ name, status: value, user: userRole }));
    };

    const handleEditModal = (sumText: string, obgName: string) => {
        setShowModal(true);
        setSumText(sumText);
        setObgName(obgName);
        setDisableConfirmBtn(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const editSumText = (event: React.FormEvent<HTMLNexusTextareaElement>) => {
        const target = event.target as HTMLNexusTextareaElement;
        dispatch(storeEditSumText(target.value));
        target && setDisableConfirmBtn(false);
    };

    const handleEditSubmit = () => {
        dispatch(updateEditSumText({ sum_text: editedSumText, name: obgName }));
        setShowModal(false);
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('obligationSummary.editSuccessMessage'),
                    time: 5000,
                    variant: 'success',
                },
            ]),
        );
    };

    return (
        <div data-testid='nexus-draft-obligation-summary'>
            {/*obligationsToSave?.length ? (
                <div className='padding-label'>
                    {UploadedDocumentText} {fullObligationsData.regulation_id}
                </div>
            ) : (
                <></>
            )*/}
            <TitleBar title={i18n.t('obligationSummary.titleDraftObligations')} />
            {obligationsToSave?.length ? (
                <>
                    <table className='nexus-table nexus-flex-1' data-testid='obligations-table'>
                        <thead>
                            <tr>
                                {(userRole === UserRole.ANALYST ? headerAnalyst : headerReviewer).map(
                                    ({ id, label }) => (
                                        <th key={id} data-testid={`column-header-${id}`}>
                                            {label}
                                        </th>
                                    ),
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {obligationsToSave
                                ?.slice(pagesVisited(), pagesVisited() + limit)
                                .map(
                                    ({
                                        obligation_name,
                                        obligation_highlighted_text,
                                        obligation_summary_text,
                                        confidence,
                                        status,
                                        status_to_be_reviewed,
                                    }: IObligations) => {
                                        return (
                                            <tr data-testid='row-1' key={obligation_name}>
                                                <td
                                                    data-testid='obligation-summary-obligation-name'
                                                    className='row-width'
                                                >
                                                    {obligation_name}
                                                </td>
                                                <td className='row-width'>
                                                    <TooltipComponent
                                                        message={obligation_highlighted_text}
                                                        placement={tooltipPosition}
                                                        className='obligation-tooltip'
                                                    >
                                                        <span
                                                            data-testid='obligation-summary-highlighted-text'
                                                            className='ellipsis'
                                                        >
                                                            {obligation_highlighted_text}
                                                        </span>
                                                    </TooltipComponent>
                                                </td>
                                                <td className='row-width'>
                                                    <span
                                                        data-testid='obligation-summary-obligation-summary-text'
                                                        className='ellipsis'
                                                    >
                                                        {obligation_summary_text}
                                                    </span>
                                                </td>
                                                <td className='row-width'>
                                                    <button
                                                        className='edit-btn'
                                                        onClick={() =>
                                                            handleEditModal(obligation_summary_text, obligation_name)
                                                        }
                                                    >
                                                        <NexusIcon
                                                            data-testid='nexus-icon-in-obligation-summary-table'
                                                            size='md'
                                                            src={Edit24px}
                                                        ></NexusIcon>
                                                    </button>
                                                </td>
                                                <td data-testid='obligation-summary-confidence' className='row-width'>
                                                    <ChipComponent
                                                        title={getValuePercentage(confidence) + percentage}
                                                        // title={getValuePercentage(Math.random() * 20 + 75) + percentage}
                                                        contentStyles='rcm-chip-content-style'
                                                    />
                                                </td>
                                                {userRole === UserRole.REVIEWER && (
                                                    <td className='row-width'>
                                                        <span data-testid='analyst_status'>
                                                            {status_to_be_reviewed}
                                                        </span>
                                                    </td>
                                                )}
                                                <td>
                                                    <div className='nexus-row'>
                                                        <ApproveIcon
                                                            status={
                                                                userRole === UserRole.REVIEWER
                                                                    ? status
                                                                    : status_to_be_reviewed
                                                            }
                                                            handleApproveIcon={() =>
                                                                handleOptionsChange(
                                                                    StatusChose.APPROVED,
                                                                    obligation_name,
                                                                )
                                                            }
                                                        />
                                                        <RejectIcon
                                                            status={
                                                                userRole === UserRole.REVIEWER
                                                                    ? status
                                                                    : status_to_be_reviewed
                                                            }
                                                            handleRejectIcon={() =>
                                                                handleOptionsChange(
                                                                    StatusChose.REJECTED,
                                                                    obligation_name,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                        </tbody>
                    </table>
                    <PaginationComponent
                        selectedPage={selectedPage}
                        offset={0}
                        limit={limit}
                        size={obligationsToSave.length}
                        page={page}
                    />

                    <ControlButtons submit={handleSubmit} save={handleSave} validate={validateTableRows()} />

                    {showModal && (
                        <ModalComponent
                            showModal={showModal}
                            closeModal={closeModal}
                            headerContent={i18n.t('obligationSummary.edit') + obgName}
                            modalBodyContent={modalBody}
                            primaryBtnText={i18n.t('approvalModal.confirm')}
                            primaryBtnTestId='obligation-text-area-modal-confirm'
                            secondaryBtnText={i18n.t('approvalModal.cancel')}
                            primaryBtnOnclickCallBack={handleEditSubmit}
                            secondaryBtnTestId='obligation-text-area-modal-cancel'
                            secondaryBtnOnclickCallBack={closeModal}
                            size='md'
                            disableConfirmBtn={disableConfirmBtn}
                        />
                    )}
                </>
            ) : (
                <NoRecordsTemplate />
            )}
        </div>
    );
};

export default ObligationSummary;
