const NAME = 'homeData';

const actionConst = {
    addReviewerData: `${NAME}/addReviewerData`,
    assessControl: `${NAME}/assessControl`,
    assessControlsToSave: `${NAME}/assessControlsToSave`,
    auMapping: `${NAME}/auMapping`,
    auToSave: `${NAME}/auToSave`,
    clearAssessControlData: `${NAME}/clearAssessControlData`,
    clearAuMappingData: `${NAME}/clearAuMappingData`,
    clearNotifications: `${NAME}/clearNotifications`,
    clearObligationsData: `${NAME}/clearObligationsData`,
    editSumText: `${NAME}/editSumText`,
    notificationData: `${NAME}/notificationData`,
    obligationData: `${NAME}/obligationData`,
    obligationsToSave: `${NAME}/obligationsToSave`,
    obligationsToSaveSumText: `${NAME}/obligationsToSaveSumText`,
    saveAnalystAssessControl: `${NAME}/saveAnalystAssessControl`,
    saveAnalystControlMapping: `${NAME}/saveAnalystControlMapping`,
    saveAnalystObligations: `${NAME}/saveAnalystObligations`,
    saveReviewerObligations: `${NAME}/reviewer/saveAnalystObligations`,
    savedObligationData: `${NAME}/savedObligationData`,
    selectedHomeCategory: `${NAME}/homeCategory`,
    selectedHomeTypeFile: `${NAME}/homeFile`,
    selectedStep1Category: `${NAME}/step1Category`,
    setPaginationFromOutside: `${NAME}/setPaginationFromOutside`,
    storeCommonUploadedFiles: `${NAME}/commonUploadedFiles`,
    textAreaData: `${NAME}/textareaData`,
    uploadAllPageFile: `${NAME}/uploadAllFiles`,
    uploadAssessControlFileToBackend: `${NAME}/uploadAssessControlFileToBackend`,
    uploadAuMappingFileToBackend: `${NAME}/uploadAuMappingFileToBackend`,
    uploadedObligationFileData: `${NAME}/uploadedObligationFileData`,
    userRole: `${NAME}/userRole`,
};

export default actionConst;
