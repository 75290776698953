import i18n from 'locales/i18n';

interface IHeaderDraftObligation {
    id: string;
    label: string;
}

export const headerAnalyst: IHeaderDraftObligation[] = [
    {
        id: 'citation_section',
        label: i18n.t('obligationSummary.citationSection'),
    },
    {
        id: 'obligation_highlighted_text',
        label: i18n.t('obligationSummary.obligationHighlightedText'),
    },
    {
        id: 'obligation_summary-text',
        label: i18n.t('obligationSummary.obligationSummaryText'),
    },
    {
        id: 'edit',
        label: ' ',
    },
    {
        id: 'confidence',
        label: i18n.t('obligationSummary.confidence'),
    },
    {
        id: 'approve/reject',
        label: i18n.t('obligationSummary.approve/reject'),
    },
];

export const headerReviewer: IHeaderDraftObligation[] = [
    {
        id: 'citation_section',
        label: i18n.t('obligationSummary.citationSection'),
    },
    {
        id: 'obligation_highlighted_text',
        label: i18n.t('obligationSummary.obligationHighlightedText'),
    },
    {
        id: 'obligation_summary-text',
        label: i18n.t('obligationSummary.obligationSummaryText'),
    },
    {
        id: 'edit',
        label: ' ',
    },
    {
        id: 'confidence',
        label: i18n.t('obligationSummary.confidence'),
    },
    {
        id: 'analyst_status',
        label: i18n.t('obligationSummary.analystStatus'),
    },
    {
        id: 'approve/reject',
        label: i18n.t('obligationSummary.approve/reject'),
    },
];
