import React from 'react';

import { NexusHeader, NexusHeaderMenu, NexusMenuItem, NexusHeaderLogo, NexusIcon } from '@nexus/react';
// import Logo from '@nexus/core/dist/assets/images/nexus-logo.svg';
import Logo from '../../../../../src/logo.svg';
import { Link } from 'react-router-dom';
import './header.scss';

interface menuItem {
    icon?: string;
    link: string;
    title: string;
}
interface Props {
    home: string;
    menuItems: menuItem[];
}
const HeaderComponent: React.FC<Props> = ({ home, menuItems }) => (
    <NexusHeader class='nexus-header-sticky' data-testid='nexus-header'>
        <NexusHeaderLogo style={{ fontWeight: 600 }}>
            <Link to={home}>
                <NexusIcon src={Logo} />
                <span className='nexus-visually-hidden'></span>
            </Link>
            <div className='header-flex-style'>
                <span style={{ fontSize: '20px' }}> | </span>
                <span style={{ fontSize: '16px', padding: '1px 0 0 18px' }}>
                    {' '}
                    Regulatory Change Management Platform (RCM){' '}
                </span>
            </div>
        </NexusHeaderLogo>

        <NexusHeaderMenu data-testid='nexus-header-menu'>
            {menuItems.map((menuItem, idx) =>
                menuItem.icon ? (
                    <NexusMenuItem key={idx} data-testid={menuItem.title}>
                        <Link to={menuItem.link}>
                            <NexusIcon src={menuItem.icon}>{menuItem.title}</NexusIcon>
                        </Link>
                    </NexusMenuItem>
                ) : (
                    <NexusMenuItem key={idx} data-testid={menuItem.title}>
                        <Link to={menuItem.link}>{menuItem.title}</Link>
                    </NexusMenuItem>
                ),
            )}
        </NexusHeaderMenu>
    </NexusHeader>
);

export default HeaderComponent;
