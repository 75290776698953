import { NexusModal, NexusModalBody, NexusModalFooter, NexusModalHeader } from '@nexus/react';

import React from 'react';

interface IModalProps {
    showModal?: boolean;
    headerContent?: string | React.ReactNode;
    size?: 'full' | 'lg' | 'md' | 'xl' | 'xs';
    subHeaderContent?: string;
    modalBodyContent?: React.ReactNode;
    primaryBtnText?: string;
    primaryBtnTestId?: string;
    secondaryBtnText?: string;
    secondaryBtnTestId?: string;
    primaryBtnOnclickCallBack?: () => void;
    secondaryBtnOnclickCallBack?: () => void;
    closeModal: () => void;
    disableConfirmBtn?: boolean;
    isSecondButton?: boolean;
}
const ModalComponent = ({
    showModal,
    closeModal,
    headerContent,
    modalBodyContent,
    primaryBtnText,
    secondaryBtnText,
    primaryBtnTestId,
    size,
    primaryBtnOnclickCallBack,
    secondaryBtnOnclickCallBack,
    secondaryBtnTestId,
    disableConfirmBtn,
    isSecondButton = true,
}: IModalProps) => {
    return (
        <>
            <NexusModal data-testid='modal-overlay' id='modal' show={showModal} size={size}>
                <NexusModalHeader data-testid='modal-header' onCloseEvent={closeModal}>
                    {headerContent}
                </NexusModalHeader>
                <NexusModalBody>{modalBodyContent}</NexusModalBody>
                <NexusModalFooter>
                    <button
                        onClick={primaryBtnOnclickCallBack}
                        className='nexus-btn-primary'
                        data-testid={primaryBtnTestId}
                        disabled={disableConfirmBtn}
                    >
                        {primaryBtnText}
                    </button>
                    {isSecondButton && (
                        <button
                            onClick={secondaryBtnOnclickCallBack}
                            className='nexus-btn'
                            data-testid={secondaryBtnTestId}
                        >
                            {secondaryBtnText}
                        </button>
                    )}
                </NexusModalFooter>
            </NexusModal>
        </>
    );
};

export default ModalComponent;
