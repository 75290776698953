import { IStep } from './commonExportedInterfaces';
import i18n from 'locales/i18n';

export enum stepperRoutes {
    Home = 'home',
    Step2 = 'step2',
    Step3 = 'step3',
    Step4 = 'step4',
    Step5 = 'step5',
}

export const steps: IStep[] = [
    { name: stepperRoutes.Home, label: i18n.t('stepper.firstStepperLabel') },

    // { name: stepperRoutes.Step2, label: i18n.t('stepper.secondStepperLabel') },

    // { name: stepperRoutes.Step3, label: i18n.t('stepper.thirdStepperLabel') },

    // { name: stepperRoutes.Step4, label: i18n.t('stepper.fourthStepperLabel') },
];
